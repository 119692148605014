.header-articles {
    margin-top: 30px;

    .wrap {

        @media (min-width: 768px) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__title {
        font-size: 48px;
        font-weight: 700;
        color: $white;
        line-height: 1.2;

        b {
            color: $coral;
        }
    }

    &__text {
        color: $white;
        line-height: 1.4;

        @media (min-width: 1024px) {
            margin-top: 12px;
        }
    }

    &__search {
        position: relative;
        margin-top: 24px;
        display: flex;
        align-items: center;

        border-top: 1px solid $blue;
        padding-top: 16px;

        @media (min-width: 1024px) {
            padding-top: 0px;
            border-top: 0;
        }

        @media (min-width: 768px) {
            width: 36%;
        }

        input {

            &[type="text"] {
                background-color: rgba(#FEFDFD, 0.05);
                border: 1px solid rgba(#FEFDFD, 0.1);
                padding: 18px 12px;
                border-radius: 6px;
                width: 100%;
                color: rgba(#FEFDFD, 0.7);
                font-size: 14px;

                @media screen and (min-width: 1024px) {
                    margin-bottom: 0;
                }

                &::placeholder {
                    color: rgba(#FEFDFD, 0.7);
                }
            }

            &[type="submit"] {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 12px;
                height: 24px;
                width: 24px;
                background-image: $icon-seach;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 18px;
                cursor: pointer;
                background-color: transparent;
                border: 0;
                box-sizing: content-box;
            }
        }
    }
}