.insights {
    $s: &;
    margin-top: 48px;

    @media screen and (min-width: 1024px) {
        margin-top: 116px;
    }

    &__title {
        position: relative;
        width: 100%;
        color: $white;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 62px;
        border-top: 1px solid $blue;
        padding-top: 48px;

        @media screen and (min-width: 1024px) {
            margin-bottom: 116px;
            font-size: 72px;
            line-height: 0.7;
            border-top: 0;
        }

        b {
            color: $orange;
        }
    }

    &__subtitle {
        position: relative;
        font-weight: 700;
        color: $blue;
        background-color: $marine;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 1.2;

        @media screen and (min-width: 1024px) {
            writing-mode: vertical-lr;
            text-orientation: mixed;
            transform: rotate(180deg);
            margin-right: 40px
        }

        &::after {
            @media screen and (min-width: 1024px) {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                height: 100%;
                bottom: calc(100% + 25px);
                left: 50%;
                background-color: $coral;
            }
        }
    }

    &__block {
        margin-bottom: 48px;

        &:last-child {
            margin-bottom: 0;

            #{$s}__subtitle {
                &::after {
                    @media screen and (min-width: 1024px) {
                        display: none;
                    }
                }
            }
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: flex-start;
            margin-bottom: 104px;
        }

        &-list {
            width: 100%;
            margin-top: 40px;

            @media screen and (min-width: 1024px) {
                margin-top: 0;
            }
        }

        &-item {
            cursor: pointer;
        }

        &-image {
            width: 100%;
            border-radius: 10px;
        }

        &-name {
            margin-top: 24px;
            font-size: 20px;
            font-weight: 700;
            color: $white;
            line-height: 1.2;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                font-weight: 300;
            }
        }
    }

    &__books {
        #{$s}__block-item {
            max-width: 286px;
        }
    }

    &__video {
        #{$s}__block-item {
            max-width: 422px
        }
    }

    &__modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 925px;
        max-height: 740px;
        padding: 40px;
        border-radius: 8px;
        background-color: $white;
        z-index: 999;
        transform: translate(-50%, -50%);
        display: none;
        color: #081E3D;
        cursor: initial;

        @media screen and (min-width: 1024px) {
            overflow-y: auto;
        }

        img {
            width: auto;
            max-width: 100%;
        }

        iframe {
            width: auto;
            max-width: 100%;
        }

        &.active {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 34px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            line-height: 1.3;

            max-height: 45%;
            overflow-y: auto;

            @media screen and (min-width: 1024px) {
                max-height: unset;
                overflow-y: hidden;
            }
        }

        &-close {
            position: absolute;
            top: 40px;
            right: 40px;
            width: 24px;
            height: 24px;
            mask: $icon-close no-repeat 50% 50%;
            -webkit-mask: $icon-close no-repeat 50% 50%;
            background: $blue;

            cursor: pointer;
        }
    }
}