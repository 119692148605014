.video {
  $s: &;
  margin-bottom: 82px;

  @media screen and (min-width: 768px) {}

  &__title {
    position: relative;
    width: 100%;
    color: $white;
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px;

    @media screen and (min-width: 1024px) {
      font-size: 154px;
      line-height: 0.7;
      z-index: 15;
      top: 8px;
    }

    &--desktop-small {
      font-size: 40px;

      @media screen and (min-width: 1024px) {
        font-size: 104px;
      }
    }

    b {
      color: $orange;
    }
  }

  &__content {
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
      max-width: 82%;
      margin: 0 auto;
    }

    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: auto;
      z-index: 10;
      position: relative;
      filter: drop-shadow(0px 449px 180px rgba(0, 0, 0, 0.01)) drop-shadow(0px 252px 151px rgba(0, 0, 0, 0.05)) drop-shadow(0px 112px 112px rgba(0, 0, 0, 0.09)) drop-shadow(0px 28px 62px rgba(0, 0, 0, 0.1));
      border-radius: 8px;

      @media screen and (min-width: 1024px) {
        border-radius: 24px;
      }
    }
  }

  &__float-icon {
    position: absolute;
    right: calc(9% + -110px);
    bottom: -34px;
    z-index: 5;
    max-width: 181px;

    @media screen and (min-width: 1024px) {
      max-width: unset;
      bottom: -92px;
      right: calc(9% + -110px);
    }

    #{$s}--double-square & {

      @media screen and (min-width: 1024px) {
        left: calc(9% + -110px);
      }
    }

    &--2 {
      display: none;

      @media screen and (min-width: 1024px) {
        display: block;
        position: absolute;
        right: calc(9% + -110px);
        bottom: -92px;
        z-index: 5;

        #{$s}--double-square & {
          right: calc(9% + -110px);
          left: unset;
          bottom: 50%;
          transform: translateY(50%);
        }
      }
    }
  }
}