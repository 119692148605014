.our-funds {
    padding-bottom: 64px;

    @media screen and (min-width: 1024px) {
        padding-bottom: 140px;
    }

    &__title {
        position: relative;
        width: 100%;
        color: $coral;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;

        @media screen and (min-width: 1024px) {
            font-size: 104px;
            line-height: 0.7;
            z-index: 15;
            top: 8px;
        }

        b {
            color: $orange;
        }
    }

    &__tabs {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media screen and (min-width: 1024px) {
            margin-top: 80px;
        }
    }

    &__tab {
        position: relative;
        font-size: 14px;
        font-weight: 700;
        color: $white;
        border-bottom: 1px solid $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 22px;
        margin-bottom: 22px;
        cursor: pointer;
        transition: 0.4s;

        @media screen and (min-width: 1024px) {
            font-size: 18px;
            margin-bottom: 0;
        }

        &:hover {
            border-color: $coral;
        }

        &.active {
            color: $coral;
            text-align: center;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background-color: $coral;
            }
        }
    }

    &__content {
        display: none;
        margin-top: 32px;

        &--single {
            @media screen and (min-width: 1024px) {
                flex-direction: row !important;
                justify-content: space-between !important;
                gap: 32px;
            }
        }

        &.active {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        &-selector {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 16px;
        }

        &-selector-item {
            border: 1px solid $coral;
            padding: 14px 24px;
            border-radius: 56px;
            font-weight: 700;
            cursor: pointer;
            color: $white;
            transition: 0.4s;
            background-color: transparent;

            &:hover {
                background-color: rgba($coral, 0.5);
            }

            &.active {
                background-color: $coral;
            }
        }

        &-item {
            display: flex;
            margin-top: 32px;
            flex-direction: column;
            gap: 24px;

            @media screen and (min-width: 1024px) {
                margin-top: 48px;
                justify-content: space-between;
                gap: 0;
                flex-direction: row;
            }
        }

        &-left {
            @media screen and (min-width: 1024px) {
                width: 58%;
            }
        }

        &-right {
            @media screen and (min-width: 1024px) {
                width: 36%;
                display: flex;
                align-items: flex-start;
            }
        }

        &-title {
            position: relative;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            color: $white;
            padding-bottom: 24px;
            margin-bottom: 24px;

            &::after {
                content: "";
                width: 48px;
                height: 1px;
                background-color: $blue;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        &-desc {
            color: $white;
            line-height: 1.4;

            strong {
                color: $coral;
            }
        }

        &-block {
            margin-bottom: 68px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-docs {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-link {
            padding: 24px 22px;
            padding-right: 36px;
            border-radius: 8px;
            line-height: 1.4;
            color: $white;
            border: 1px solid $blue;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: 0.4s;

            &:hover {
                background: $blue;
            }

            &--highlight {
                background: $blue;
            }

            &:after {
                content: url("data:image/svg+xml,%3Csvg width='10' height='19' viewBox='0 0 10 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 17.5L9 9.5L0.999999 1.5' stroke='%2347BCA1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                height: 18px;
            }

            &--download {
                &::after {
                    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2Z' fill='%2347BCA1'/%3E%3C/svg%3E%0A");
                    height: 16px;
                }
            }
        }
    }

    &__subcontent-selector {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        gap: 16px;
        overflow-x: auto;

        @media screen and (min-width: 1024px) {
            align-items: center;
            flex-direction: row;
            overflow-x: hidden;
        }

        &-item {
            flex: 0 0 auto;
            border: 1px solid $coral;
            padding: 14px 24px;
            border-radius: 56px;
            font-weight: 700;
            cursor: pointer;
            color: $white;
            transition: 0.4s;
            background-color: transparent;
            width: auto;
            text-align: center;

            @media screen and (min-width: 1024px) {
                width: auto;
            }

            &:hover {
                background-color: rgba($coral, 0.5);
            }

            &.active {
                background-color: $coral;
            }
        }
    }

    &__subcontent {
        width: 100%;

        &-item {
            display: none;
            margin-top: 32px;
            flex-direction: column;
            gap: 24px;

            &.active {
                display: flex;
            }

            @media screen and (min-width: 1024px) {
                margin-top: 48px;
                justify-content: space-between;
                gap: 0;
                flex-direction: row;
            }
        }
    }

    &__fund-detail {
        background-color: $white;
        border-radius: 8px;
        padding: 40px;

        @media screen and (min-width: 1024px) {
            position: sticky;
            top: 20px;
            left: 0;
        }

        &--header {
            padding-bottom: 16px;
            margin-bottom: 32px;
            border-bottom: 1px solid $coral;
            display: flex;
            justify-content: space-between;

            span {
                color: $marine;

                &:first-child {
                    color: $coral;
                    font-weight: 700;
                }
            }
        }

        &--content {
            &-line {
                margin-bottom: 24px;
                display: grid;
                align-items: center;
                grid-template-columns: repeat(3, 1fr);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-text {
                color: $marine;

                &--uppercase {
                    text-transform: uppercase;
                }

                &:nth-child(2) {
                    padding-left: 16px;
                    font-weight: 700;
                }

                &:last-child {
                    font-style: italic;
                    text-align: right;
                }
            }
        }
    }

    &__fund-performance {
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-left {
            margin-top: 16px;

            @media screen and (min-width: 1024px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 40px;

                img {
                    display: none;
                }
            }
        }

        &-menu {
            width: 100%;

            @media screen and (min-width: 1024px) {
                display: flex;
                gap: 10px;
            }
        }

        &-menu-item {
            background-color: transparent;
            border-radius: 8px;
            color: $blue;
            padding: 12px;
            line-height: 1.4;
            text-decoration: none;
            border: 1px solid $blue;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            transition: 0.4s;
            width: 100%;
            margin-bottom: 16px;

            @media screen and (min-width: 1024px) {
                padding: 16px;
            }

            &:hover,
            &.active {
                background-color: $blue;
                color: $white;
            }
        }

        &-contents {
            @media screen and (min-width: 1024px) {
                width: 100%;
            }
        }

        &-content-item {
            display: none;
            margin-top: 12px;

            &.active {
                display: block;
            }

            iframe {
                // min-height: 470px;
                max-height: 100%;
                // aspect-ratio: unset;
            }
        }
    }

    &__fund-reports {
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: auto !important;
        }

        &-item {
            display: none;
            align-items: flex-start;
            margin-bottom: 34px;

            @media screen and (min-width: 1024px) {
                max-width: calc(33.3% - 32px);
            }

            &.active {
                display: flex;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-preview {
                border-radius: 4px;
                overflow: hidden;
                margin-right: 32px;
                min-width: 100px;
            }

            &-info {
                width: calc(100% - 132px);
            }

            &-title {
                color: $blue;
                font-size: 20px;
                font-weight: 700;
                width: 100%;
                position: relative;
                padding-bottom: 16px;
                margin-bottom: 16px;

                &::after {
                    content: "";
                    width: 48px;
                    height: 1px;
                    background-color: $blue;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                b {
                    color: $coral;
                }
            }

            &-desc {
                font-style: italic;
                font-size: 18px;
                color: $marine;

                strong {
                    font-style: normal;
                }
            }

            &-button {
                display: flex;
                text-align: center;
                padding: 16px 24px;
                align-items: center;
                justify-content: center;
                background: linear-gradient(87.46deg, #1757b6 5.42%, #296caf 147.88%);
                border-radius: 80px;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.18;
                letter-spacing: 0.24em;
                text-transform: uppercase;
                color: $light-gray;
                margin-top: 16px;

                @media screen and (min-width: 1024px) {
                    font-size: 16px;
                }

                &::before {
                    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_364_997' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_364_997)'%3E%3Cpath d='M12 17L16 13L14.6 11.6L13 13.15V9H11V13.15L9.4 11.6L8 13L12 17ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V8L10 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V4H10.85L6 8.85V20Z' fill='%2347BCA1'/%3E%3C/g%3E%3C/svg%3E%0A");
                    margin-right: 12px;
                }
            }
        }

        &-select {
            margin-top: 14px;
            position: relative;

            @media screen and (min-width: 1024px) {
                width: 100%;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: calc(100% - 274px);
                    height: 1px;
                    background-color: $coral;
                }
            }
        }

        &--select {
            position: relative;
            min-width: 200px;

            @media screen and (min-width: 1024px) {
                width: 258px;
                display: inline-block;
                gap: 16px;
            }

            svg {
                position: absolute;
                right: 12px;
                top: calc(50% - 3px);
                width: 10px;
                height: 6px;
                stroke-width: 2px;
                stroke: $pool;
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
                pointer-events: none;
            }

            select {
                -webkit-appearance: none;
                padding: 14px 40px 14px 12px;
                width: 100%;
                border: 1px solid $marine;
                border-radius: 5px;
                background: rgba(#fefdfd, 0.1);
                box-shadow: 0 1px 3px -2px #9098a9;
                cursor: pointer;
                font-family: inherit;
                font-size: 14px;
                transition: all 150ms ease;
                color: $marine;
                line-height: 1;

                &:required:invalid {
                    color: $marine;
                }

                option {
                    color: #223254;

                    &[disabled] {
                        display: none;
                    }
                }

                &:focus {
                    outline: none;
                    border-color: $blue;
                    box-shadow: 0 0 0 2px rgba($blue, 0.2);
                }

                &:hover+svg {
                    stroke: $blue;
                }
            }
        }

        .sprites {
            position: absolute;
            width: 0;
            height: 0;
            pointer-events: none;
            user-select: none;
        }

        &-list {
            margin-top: 20px;
            width: 100%;

            @media screen and (min-width: 1024px) {
                margin-top: 48px;
                display: flex;
                align-items: flex-start;
                gap: 48px;
                flex-wrap: wrap;
            }
        }
    }

    &__modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 1240px;
        max-height: 750px;
        padding: 36px;
        border-radius: 8px;
        background-color: $white;
        z-index: 999;
        transform: translate(-50%, -50%);
        display: none;
        overflow-y: auto;

        @media screen and (min-width: 1024px) {
            overflow-y: hidden;
        }

        &.active {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media(max-width:1023px) {
                order: 1;
            }
        }

        &-title {
            font-size: 20px;
            line-height: 1.4;
            font-weight: 700;
            color: $marine;

            b {
                color: $coral;
            }
        }

        &-close {
            width: 24px;
            height: 24px;
            mask: $icon-close no-repeat 50% 50%;
            -webkit-mask: $icon-close no-repeat 50% 50%;
            background: $blue;

            cursor: pointer;
        }
    }

    &__fund-performance-left {
        @media(max-width:1023px) {
            order: 3;
        }
    }

    &__fund-performance-contents {
        @media(max-width:1023px) {
            order: 2;
        }
    }

    &__fund-reports-select {
        @media(max-width:1023px) {
            order: 2;
        }
    }

    &__fund-reports-list {
        @media(max-width:1023px) {
            order: 3;
        }
    }
}