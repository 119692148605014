.highlighted-article {
    &__title {
        font-size: 32px;
        font-weight: 300;
        color: $white;
        line-height: 1.2;

        b {
            color: $coral;
        }
    }

    &__item {
        margin-top: 30px;
        background-color: $blue;

        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__content {
        padding: 32px;

        @media (min-width: 768px) {
            padding: 40px;
        }

        @media (min-width: 1024px) {
            width: 52%;
        }
    }

    &__category {
        display: inline-block;
        background-color: $pool;
        padding: 8px 16px;
        border-radius: 24px;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;
        text-align: center;

        @media (min-width: 1024px) {
            margin-top: 0;
        }
    }

    &__title-article {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        margin-top: 28px;
        color: $white;

        a {
            color: $white;
        }
    }

    &__desc {
        margin-top: 12px;
        line-height: 1.2;
        color: $white;

        @media (min-width: 1024px) {
            margin-top: 28px;
        }
    }

    &__button {
        display: inline-block;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 0.24rem;
        border: 2px solid $white;
        border-radius: 80px;
        padding: 14px 32px 14px 30px;
        line-height: 16px;
        transition: 0.4s;
        margin-top: 32px;
        padding: 14px 32px 14px 30px;

        @media (min-width: 1024px) {
            margin-top: 40px;
        }
    }

    &__thumb {
        display: block;
        @media (min-width: 1024px) {
            width: 48%;
        }

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center right;
        }
    }
}