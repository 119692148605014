.logos {
    position: relative;
    z-index: 10;
    $logos: &;
    background-color: $marine;
    padding: 32px 0;

    @media screen and (min-width: 1024px) {
        padding: 126px 0 44px 0;
    }

    &__title {
        position: relative;
        width: 100%;
        color: $blue;
        font-weight: 700;
        font-size: 40px;

        &--float-icon {
            display: none;

            @media screen and (min-width: 1024px) {
                #{$logos}--without-float-icon & {
                    display: none;
                }

                display: block;
                position: absolute;
                bottom: 0;
                right: 165px;
                z-index: 15;
            }
        }

        @media screen and (min-width: 1024px) {
            text-align: left;
        }
    }

    &__list {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(5, 1fr);
            margin-top: 56px;
        }
    }

    &__item {
        position: relative;
        border-radius: 8px;
        border: 1px solid transparent;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 104px;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            border-radius: 8px;
            padding: 1px;
            /* control the border thickness */
            background: linear-gradient(to right, rgba($white, 0.08), rgba($white, 0.1));
            -webkit-mask:
                linear-gradient($white 0 0) content-box,
                linear-gradient($white 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
        }
    }
}