.post-tags {
  $s: &;
  background-color: $white;
  padding-top: 64px;
  padding-bottom: 56px;

  @media (min-width: 1024px) {
    padding-bottom: 104px;
  }

  &--transparent {
    background-color: transparent;
  }

  &--p0 {
    padding: 0;
  }

  .wrap {
    padding-top: 40px;
    border-top: 1px solid $blue;
  }

  &__title {
    font-style: italic;
    color: #081e3d;

    #{$s}--transparent & {
      color: $white;
    }
  }

  &__list {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 768px;
  }

  &__item {
    a {
      display: inline-block;
      background-color: $pool;
      padding: 8px 16px;
      border-radius: 24px;
      font-size: 14px;
      text-transform: uppercase;
      color: $white;
      text-align: center;
      margin-top: 32px;

      #{$s}--p0 & {
        margin-top: 0px;
      }

      @media (min-width: 1024px) {
        margin-top: 0;
      }
    }
  }

  &__tags {
    margin-top: 36px;
  }
}