.big-title {
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        min-height: 100vh;
    }

    &__title {
        position: relative;
        width: 100%;
        color: $white;
        font-weight: 700;
        font-size: 48px;
        line-height: 1.2;
        text-align: center;
        top: 40px;

        @media screen and (min-width: 1024px) {
            font-size: 80px;
            line-height: 0.7;
            z-index: 15;
            top: 0px;
        }

        b {
            color: $orange;
        }
    }

    &__arrow {
        display: none;
        opacity: 0;
        transform: translateY(-10px);
        animation: arrow-bounce 1.5s ease-in-out infinite;

        @media screen and (min-width: 1024px) {
            display: block;
        }

        &:nth-child(1) {
            animation-duration: 3s;
            animation-delay: 1.8s;
        }

        &:nth-child(2) {
            animation-duration: 3s;
            animation-delay: 2s;
        }

        &:nth-child(3) {
            animation-duration: 3s;
            animation-delay: 2.2s;
        }

        &-link {
            position: absolute;
            bottom: 24px;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}

@keyframes arrow-bounce {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    50% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}