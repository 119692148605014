.fund-reports {
  @media screen and (min-width: 1024px) {
    padding-bottom: 54px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__title {
    color: $white;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid $coral;
    width: 100%;
  }

  &__select {
    position: relative;
    min-width: 200px;
    width: 100%;
    transform: translateY(50%);

    @media screen and (min-width: 768px) {
      width: auto;
      margin-left: 16px;
    }

    svg {
      position: absolute;
      right: 12px;
      top: calc(50% - 3px);
      width: 10px;
      height: 6px;
      stroke-width: 2px;
      stroke: $pool;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      pointer-events: none;
    }

    select {
      -webkit-appearance: none;
      padding: 14px 40px 14px 12px;
      width: 100%;
      border: 1px solid rgba(#fefdfd, 0.05);
      border-radius: 5px;
      background: rgba(#fefdfd, 0.1);
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 14px;
      transition: all 150ms ease;
      color: #fff;
      line-height: 1;

      &:required:invalid {
        color: #fff;
      }

      option {
        color: #223254;

        &[disabled] {
          display: none;
        }
      }

      &:focus {
        outline: none;
        border-color: $blue;
        box-shadow: 0 0 0 2px rgba($blue, 0.2);
      }

      &:hover + svg {
        stroke: $blue;
      }
    }
  }

  .sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }

  &__content {
    padding-top: 56px;
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &-preview {
      border-radius: 4px;
      overflow: hidden;
      margin-right: 32px;
    }

    &-title {
      color: $white;
      font-size: 24px;
      font-weight: 700;
      width: 100%;
      position: relative;
      padding-bottom: 24px;
      margin-bottom: 24px;

      @media screen and (min-width: 768px) {
        font-size: 32px;
      }

      &::after {
        content: "";
        width: 48px;
        height: 1px;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      b {
        color: $coral;
      }
    }

    &-desc {
      font-style: italic;
      font-size: 18px;
      color: $white;

      strong {
        font-style: normal;
      }
    }

    &-button {
      display: flex;
      text-align: center;
      padding: 16px 24px;
      align-items: center;
      justify-content: center;
      background: linear-gradient(87.46deg, #1757b6 5.42%, #296caf 147.88%);
      border-radius: 80px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.18;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      color: $light-gray;
      margin-top: 44px;

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_364_997' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_364_997)'%3E%3Cpath d='M12 17L16 13L14.6 11.6L13 13.15V9H11V13.15L9.4 11.6L8 13L12 17ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V8L10 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V4H10.85L6 8.85V20Z' fill='%2347BCA1'/%3E%3C/g%3E%3C/svg%3E%0A");
        margin-right: 12px;
      }
    }
  }
}
