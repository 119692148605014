.team {
  position: relative;
  padding: 64px 0;

  @media screen and (min-width: 768px) {
    padding-top: 152px;
  }

  &__title {
    position: relative;
    width: 100%;
    color: $white;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: center;

    b {
      color: $coral;
    }
  }

  &__list {
    margin-top: 32px;
    display: grid;
    gap: 16px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 80px;
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    $item: &;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(212.09deg, rgba(36, 173, 244, 0.5) -33.35%, rgba(26, 49, 83, 0.5) 101.74%);
    padding: 12px 32px 0 32px;
    text-align: center;
    cursor: pointer;
    height: 426px;
    overflow: hidden;

    &::after {
      content: "";
      height: 50%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 20;

      background: linear-gradient(189.12deg, rgba(0, 0, 0, 0) 16.45%, rgba(0, 0, 0, 0.5) 106.87%);
      border-radius: 16px 16px 0 0;
    }

    &--link {
      position: absolute;
      top: 32px;
      right: 32px;
    }

    &--content {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0 32px 32px 32px;
      z-index: 30;
      text-align: left;
    }

    &--photo-area {
      position: absolute;
      height: 426px;
      width: 100%;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
    }

    &--photo {
      object-fit: cover;
    }

    &--modal {
      position: fixed;
      display: none;
      padding: 32px;
      width: 90vw;
      height: 100%;
      max-height: 90vh;
      overflow: auto;
      top: 50%;
      transform: translateY(-50%);
      left: 5vw;
      background: linear-gradient(212.09deg, #24adf4 -33.35%, #1a3153 101.74%);
      z-index: 110;
      border-radius: 16px;

      @media screen and (min-width: 768px) {
        min-height: 380px;
        height: auto;
        max-width: 770px;
        max-height: 380px;
        overflow: hidden;
        top: 50%;
        left: 50%;
        padding: 0px;
        transform: translate(-50%, -50%);
        gap: 40px;
      }

      #{$item}.active & {
        display: block;
        opacity: 1;

        @media screen and (min-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      &-close {
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_266_3050' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_266_3050)'%3E%3Cpath d='M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
        position: absolute;
        right: 16px;
        top: 16px;
        display: block;
        cursor: pointer;
      }

      &-left {
        text-align: center;

        #{$item}--modal-content {
          @media screen and (min-width: 768px) {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        @media screen and (min-width: 768px) {
          width: 35%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &-right {
        margin-top: 16px;
        text-align: left;

        @media screen and (min-width: 768px) {
          width: 58%;
          margin-top: 0;
          padding: 32px 0;
          padding-right: 75px;
          max-height: 380px;
          overflow: auto;
        }

        @media screen and (min-width: 1024px) {
          padding-top: 48px;
          padding-right: 35px;
          padding-bottom: 48px;
          max-height: 380px;
          overflow: auto;
        }

        #{$item}--content {
          position: relative;
        }
      }

      &-content {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        @media screen and (min-width: 768px) {
          padding: 0 16px 16px 16px;
          border-bottom: 0;
        }

        @media screen and (min-width: 1024px) {
          padding: 0 0 48px 48px;
          border-bottom: 0;
        }
      }

      // &-photo-area {
      //   @media screen and (min-width: 768px) {
      //     height: 100%;
      //     width: 100%;
      //   }
      // }

      &-photo {
        margin-bottom: 16px;
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
          margin-bottom: 0px;
          transform-origin: 0 100%;
        }
      }

      &-name {
        font-size: 18px;
        color: $white;
        line-height: 1.2;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        text-align: left;

        @media screen and (min-width: 768px) {
          font-size: 20px;
        }

        &::after {
          content: "";
          display: block;
          background-color: $coral;
          height: 4px;
          width: 62px;
          margin-top: 24px;
          border-radius: 4px;
        }
      }

      &-desc {
        width: 100%;
        text-align: left;

        p {
          font-size: 14px;
          font-weight: 500;
          font-style: italic;
          color: $white;
          margin-top: 24px;

          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }

      &-title {
        font-size: 20px;
        color: $white;
        line-height: 1.5;
        font-weight: 700;
        margin-bottom: 12px;

        b {
          color: $coral;
        }
      }

      &-content-bibliography {
        p {
          color: $white;
          line-height: 1.5;
          margin-bottom: 8px;

          @media screen and (min-width: 768px) {
            font-size: 16px;
            margin-bottom: 16px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &--name {
      font-size: 18px;
      color: $white;
      line-height: 1.2;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &::after {
        content: "";
        display: block;
        background-color: $coral;
        height: 4px;
        width: 62px;
        margin-top: 24px;
        border-radius: 4px;
      }
    }

    &--desc {
      p {
        font-size: 14px;
        font-weight: 500;
        font-style: italic;
        color: $white;
        margin-top: 24px;
      }
    }
  }
}