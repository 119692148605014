html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Inter", sans-serif;
    width: 100%;
    overflow-x: hidden;
    background-color: $marine;

    &.no-scroll {
        overflow: hidden;
    }
}

textarea {
    font-family: "Inter", sans-serif;
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

.shadow {
    display: none;
    background-color: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;

    &.active {
        display: block;
    }
}

.container {
    position: relative;
    max-width: #{$container}px;
    margin: 0 auto;
    padding: 0 32px;

    @media screen and (min-width: #{$container}px) {
        padding: 0;
    }
}

.header-section {
    max-width: 768px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 32px;

    &__title {
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 12px;

        @media (min-width: 1024px) {
            font-size: 32px;
        }
    }

    &__description {
        p {
            margin-bottom: 8px;
            line-height: 1.5;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}