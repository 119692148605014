.news {
    $s: &;
    background-color: $marine;
    padding: 32px 0;

    &--white {
        background-color: $white;
    }

    &--p0 {
        padding: 0;
    }

    @media screen and (min-width: 1024px) {
        padding: 44px 0 88px 0;
    }

    .wrap {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__content {
        position: relative;
        background-color: #102442;
        border-radius: 8px 8px 0 0;
        padding: 32px;
        margin: 0 32px;

        @media screen and (min-width: 1024px) {
            margin: 0;
            width: 50%;
            padding: 170px 66px 170px 104px;
            border-radius: 8px 0 0 8px;
        }
    }

    &__float-icon {
        position: absolute;
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
            position: absolute;
            left: 48px;
            top: -38px;
        }
    }

    &__title {
        color: $pool;
        font-weight: 300;
        font-size: 32px;
        text-align: center;
        line-height: 1.2;

        #{$s}--sidebar & {
            color: $white;
            font-size: 24px;
            margin-bottom: 25px;
        }

        @media screen and (min-width: 1024px) {
            text-align: left;
            font-size: 40px;
        }
    }

    &__form {
        padding: 32px;
        border-radius: 8px;
        background-color: $blue;

        @media screen and (min-width: 1024px) {
            margin-top: 32px;
            width: 50%;
            padding: 48px;

            #{$s}--sidebar & {
                width: 100%;
                padding: 24px;
            }
        }

        &-fields {
            @media screen and (min-width: 1024px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                margin-bottom: 16px;

                #{$s}--sidebar & {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }

        input {

            &[type="text"],
            &[type="email"] {
                background-color: rgba(#FEFDFD, 0.05);
                border: 1px solid rgba(#FEFDFD, 0.1);
                padding: 22px 12px;
                border-radius: 6px;
                width: 100%;
                margin-bottom: 16px;
                color: rgba(#FEFDFD, 0.7);
                font-size: 14px;

                @media screen and (min-width: 1024px) {
                    margin-bottom: 0;
                }

                &::placeholder {
                    color: rgba(#FEFDFD, 0.7);
                }
            }

            &[type="checkbox"] {
                display: none;
            }

            &[type="submit"] {
                display: block;
                background: linear-gradient(86.39deg, $pool 33.02%, $blue 182.04%);
                border-radius: 80px;
                padding: 16px 40px;
                letter-spacing: 0.24em;
                text-transform: uppercase;
                color: $light-gray;
                font-weight: 300;
                margin-top: 32px;
                border: none;
                width: 100%;
                font-size: 16px;
                cursor: pointer;

                @media screen and (min-width: 1024px) {
                    padding: 22px 40px;
                    margin-top: 40px;
                }
            }
        }

        label {
            display: flex;
            color: $white;
            font-size: 14px;
            margin-bottom: 12px;
            line-height: 1.3;

            span {
                &:not(.custom-checkbox) {
                    width: calc(100% - 18px + 12px);
                }
            }

            a {
                color: $white;
                text-decoration-line: underline;
                text-underline-offset: 2px;
            }

            .wpcf7-list-item-label {
                display: flex;
            }
        }

        /* Estilo para o checkbox personalizado */
        .custom-checkbox {
            width: 18px;
            min-width: 18px;
            height: 18px;
            border: 2px solid $pool;
            border-radius: 2px;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 12px;
        }

        /* Quando o checkbox está marcado */
        input[type="checkbox"]:checked+.wpcf7-list-item-label>.custom-checkbox {
            background-color: $pool;
            border-color: $pool;
        }

        /* Estilo do check (✓) */
        .custom-checkbox::before {
            content: "";
            width: 4px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity 0.2s;
        }

        /* Mostra o check quando o checkbox está marcado */
        input[type="checkbox"]:checked+.wpcf7-list-item-label>.custom-checkbox::before {
            opacity: 1;
        }

        .divider {
            border-bottom: 1px solid rgba($marine, .3);
            margin: 12px 0;

            @media screen and (min-width: 1024px) {
                margin: 22px 0;
            }
        }

        .disclaimer {
            color: $white;
            line-height: 1.4;
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-top: 26px;

            img {
                margin-right: 12px;
            }
        }

        .wpcf7-list-item {
            margin: 0;
        }

        .wpcf7-response-output {
            padding: 1rem !important;
            color: $white;
            line-height: 1.3;
            border-color: transparent !important;
            text-align: center;
        }

        form {
            &.sent .wpcf7-response-output {
                border-color: #2ecc71 !important;
                background: #2ecc71 !important;
            }

            &.failed .wpcf7-response-output,
            &.aborted .wpcf7-response-output {
                border-color: #e74c3c !important;
                background: #e74c3c !important;
            }

            &.invalid .wpcf7-response-output,
            &.unaccepted .wpcf7-response-output,
            &.payment-required .wpcf7-response-output {
                border-color: #f1c40f !important;
                background: #f1c40f !important;
            }
        }
    }
}