.internal-banner {
  $item: &;
  background-color: $marine;
  padding: 120px 0 60px 0;

  @media screen and (min-width: 1024px) {
    padding: 216px 0 166px 0;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__content {
    @media screen and (min-width: 1024px) {
      max-width: 39%;
      margin-top: 40px;
    }

    #{$item}--extended & {
      @media screen and (min-width: 1024px) {
        max-width: 455px;
      }
    }
  }

  &__title {
    color: $white;
    font-size: 32px;
    line-height: 1.4;
    font-weight: 300;

    #{$item}--extended & {
      font-weight: 700;
    }

    @media screen and (min-width: 1024px) {
      font-size: 40px;
      max-width: 378px;
    }

    b {
      color: $coral;
    }

    strong {
      font-weight: 700;
    }
  }

  &__desc {
    color: $white;
    margin-top: 24px;

    @media screen and (min-width: 1024px) {
      margin-top: 108px;

      &--after-title {
        margin-top: 72px;
      }
    }

    &--after-title {}

    p {
      line-height: 1.4;
    }
  }

  &__subtitle {
    line-height: 1.4;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;

    @media screen and (min-width: 1024px) {
      font-size: 24px;
      margin-bottom: 32px;
    }
  }

  &__image-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 52px;
    max-width: 90%;

    @media screen and (min-width: 1024px) {
      max-width: 45%;
      margin-top: 0px;
      margin-right: 96px;
    }
  }

  &__image {
    &--top-left {
      margin-right: 0px;
      position: absolute;
      left: -20px;
      top: -20px;
      z-index: 100;
      max-width: 24%;

      @media screen and (min-width: 768px) {
        left: -34px;
        top: -34px;
      }

      @media screen and (min-width: 1024px) {
        left: -80px;
        top: -40px;
      }
    }

    &--principal {
      z-index: 50;
      width: 100%;

      @media screen and (min-width: 768px) {
        margin-right: 0;
      }
    }

    &--bottom-right {
      margin-right: 0px;
      position: absolute;
      max-width: 72%;
      right: -24px;
      bottom: -24px;
      z-index: 1;

      @media screen and (min-width: 768px) {
        right: -38px;
        bottom: -38px;
      }

      @media screen and (min-width: 1024px) {
        right: -100px;
        bottom: -94px;
      }
    }
  }

  &__float-icon {
    margin-bottom: 12px;

    #{$item}--extended & {
      margin-top: 20px;
      margin-bottom: 20px;

      @media screen and (min-width: 1024px) {
        margin-top: 72px;
        margin-bottom: 40px;
      }
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &__highlight {
    font-size: 26px;
    line-height: 1.4;

    @media screen and (min-width: 1024px) {
      font-size: 32px;
    }

    strong {
      color: $pool;
    }
  }
}