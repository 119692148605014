.who-are-they {
    &__title {
        font-size: 24px;
        font-weight: 700;
        color: $white;
        line-height: 1.2;

        @media (min-width: 1024px) {
            font-size: 32px;
        }

        b {
            color: $coral;
        }
    }

    &__text {
        margin-top: 24px;
        color: $white;
        line-height: 1.4;

        @media (min-width: 1024px) {
            margin-top: 32px;
        }
    }

    &__list {
        margin-top: 32px;

        padding-bottom: 48px;
        margin-bottom: 48px;
        border-bottom: 1px solid $blue;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (min-width: 1024px) {
            padding-bottom: 64px;
            margin-bottom: 72px;

            margin-top: 48px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        $item: &;
        position: relative;
        border-radius: 16px;
        background: linear-gradient(212.09deg, rgba(36, 173, 244, 0.5) -33.35%, rgba(26, 49, 83, 0.5) 101.74%);
        padding: 32px 32px 0 32px;
        cursor: pointer;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @media (min-width: 1024px) {
            flex-wrap: wrap;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            padding-left: 0;
            overflow: hidden;
        }

        &--photo {
            margin-top: 18px;

            @media (min-width: 1024px) {
                width: 40%;
                margin-top: 0px;
            }
        }

        &::after {
            content: '';
            height: 50%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 20;

            border-radius: 16px 16px 0 0;
        }

        &--link {
            position: absolute;
            top: 32px;
            right: 32px;
        }

        &--content {
            width: 100%;
            z-index: 30;
            text-align: left;

            @media (min-width: 1024px) {
                width: 55%;
                left: unset;
                right: 0;
                padding: 0 0 32px 0;
                max-width: 312px;
            }
        }

        &--buttons {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            @media (min-width: 1024px) {
                margin-top: 64px;
                gap: 14px;
            }
        }

        &--button {
            display: inline-block;
            padding: 12px;
            border-radius: 80px;

            line-height: 1.6;
            letter-spacing: 0.24em;
            text-transform: uppercase;
            color: $light-gray;
            text-align: center;
            border: 2px solid $light-gray;

            &--colorful {
                border: 0;
                background: linear-gradient(87.46deg, #47BCA1 5.42%, #296CAF 147.88%);
            }
        }

        &--modal {
            position: fixed;
            display: none;
            padding: 16px;
            width: 90vw;
            height: 90vh;
            overflow: auto;
            top: 5vh;
            left: 5vw;
            background: linear-gradient(212.09deg, #24ADF4 -33.35%, #1A3153 101.74%);
            z-index: 100;
            border-radius: 16px;

            @media screen and (min-width: 768px) {
                min-height: 380px;
                height: auto;
                max-width: 770px;
                top: 50%;
                left: 50%;
                padding: 0px;
                transform: translate(-50%, -50%);
                gap: 40px;
            }

            #{$item}.active & {
                display: block;
                opacity: 1;

                @media screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }

            &-close {
                content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_266_3050' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_266_3050)'%3E%3Cpath d='M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
                position: absolute;
                right: 16px;
                top: 16px;
                display: block;
                cursor: pointer;
            }

            &-left {
                text-align: center;

                #{$item}--modal-content {
                    @media screen and (min-width: 768px) {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }

                @media screen and (min-width: 768px) {
                    width: 35%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            &-right {
                margin-top: 16px;

                @media screen and (min-width: 768px) {
                    width: 58%;
                    margin-top: 0;
                    text-align: left;

                    max-height: 380px;
                    overflow: auto;
                    padding: 48px 0;
                    padding-right: 75px;
                }

                #{$item}--content {
                    position: relative;
                }
            }

            &-content {
                @media screen and (min-width: 768px) {
                    padding: 0 16px 16px 16px;
                }
            }

            &-photo {
                margin-bottom: 16px;

                @media screen and (min-width: 768px) {
                    display: block;
                    margin-bottom: 0px;
                    transform-origin: 0 100%;
                }
            }

            &-name {
                font-size: 20px;
                color: $white;
                line-height: 1.2;
                font-weight: 700;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                text-align: left;

                &::after {
                    content: '';
                    display: block;
                    background-color: $coral;
                    height: 4px;
                    width: 62px;
                    margin-top: 24px;
                    border-radius: 4px;
                }
            }

            &-desc {
                width: 100%;
                text-align: left;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    font-style: italic;
                    color: $white;
                    margin-top: 24px;

                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
            }

            &-title {
                font-size: 20px;
                color: $white;
                line-height: 1.5;
                font-weight: 700;
                margin-bottom: 12px;

                b {
                    color: $coral;
                }
            }

            &-content-bibliography {
                p {
                    font-size: 14px;
                    color: $white;
                    line-height: 1.5;
                    margin-bottom: 8px;

                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                        margin-bottom: 16px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &--name {
            font-size: 24px;
            color: $white;
            line-height: 1.2;
            font-weight: 700;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &::after {
                content: '';
                display: block;
                background-color: $coral;
                height: 4px;
                width: 62px;
                margin-top: 24px;
                border-radius: 4px;
            }
        }

        &--desc {
            p {
                font-size: 14px;
                font-weight: 500;
                font-style: italic;
                color: $white;
                margin-top: 24px;
            }
        }
    }
}