.maps {
  margin-bottom: 40px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 80px;
  }
  &__list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (min-width: 1024px) {
      margin-top: 98px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__item {
    background-color: rgba($white, 0.08);
    border: 1px solid rgba($white, 0.1);
    border-radius: 8px;
    padding: 24px;

    &-country {
      font-size: 20px;
      color: $white;
      text-transform: uppercase;
      font-weight: 700;
      display: flex;
      align-items: center;
    }

    &-flag {
      margin-right: 8px;
    }

    &-address {
      margin-top: 24px;
      line-height: 1.4;
      color: $white;
      @media screen and (min-width: 1024px) {
        margin-top: 32px;
      }
    }

    &-phones {
      margin-top: 16px;

      a {
        color: $white;
        font-weight: 700;
      }
    }

    &-iframe {
      margin-top: 32px;
      height: 288px;

      iframe {
        height: 100%;
      }
    }
  }
}
