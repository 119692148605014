.selection-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50;
    padding: 16px 0;

    @media screen and (min-width: 1024px) {
        margin-top: 70px;
        padding: 0 0;
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        max-width: 120px;

        @media screen and (min-width: 1024px) {
            max-width: 182px;
        }
    }

    &__social {
        display: flex;
        align-items: center;

        &-item {
            margin-right: 8px;
            background-color: rgba($white, .08);
            border: 1px solid rgba($white, .10);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .4s;

            &:hover {
                background-color: rgba($white, .10);
            }

            img {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}