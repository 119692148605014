.breadcrumbs {
    margin-top: 140px;

    @media (min-width: 768px) {
        margin-top: 172px;
    }

    &__item {
        display: inline-block;
        color: $white;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;

        &::after {
            content: '';
            display: inline-block;
            width: 5px;
            height: 10px;

            mask: $icon-arrow no-repeat 50% 50%;
            -webkit-mask: $icon-arrow no-repeat 50% 50%;
            background: $pool;

            transform: rotate(180deg);
            margin-left: 10px;
            margin-right: 6px;
        }

        &--current {
            font-weight: 600;

            &::after {
                display: none;
            }
        }
    }
}