.content-page {
  background-color: $white;
  margin-top: -1px;
  padding-top: 56px;

  .wrap {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: flex-start;
    }
  }

  &__left {
    @media (min-width: 1024px) {
      width: 86%;
      margin: 0 auto;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__author {
    font-style: italic;
    font-weight: 300;
    color: #081e3d;
    margin-top: 8px;

    a {
      color: #081e3d;
    }
  }

  &__text {
    line-height: 1.6;

    @media (min-width: 1024px) {
      margin-top: 42px;
    }

    p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin-bottom: 16px;
      list-style-position: inside;
      list-style: inside;
    }

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__social {
    display: flex;
    gap: 8px;

    @media (min-width: 1024px) {
      flex-direction: column;
      position: sticky;
      top: 24px;
      margin-bottom: 12px;
    }
  }

  &__item {

    a {
      width: 40px;
      height: 40px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.4s;

      &:hover {
        background-color: #d9d9d9;
      }
    }

    img {
      pointer-events: none;
    }

  }
}