.main-header {
    $s: &;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 26px 0;
    z-index: 101;

    @media screen and (min-width: 1024px) {
        padding: 0;
        padding-top: 60px;
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        max-width: 120px;
        transition: .4s;
        z-index: 101;

        @media screen and (min-width: 1024px) {
            max-width: 157px;
        }
    }

    &__menus {
        &--mobile {
            display: flex;
            position: absolute;
            left: 0;
            top: -26px;
            width: 100%;
            min-height: 100vh;
            background-color: $marine;
            z-index: 100;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            padding: 32px;
            padding-top: 100px;
            transition: .5s;
            pointer-events: none;
            opacity: 0;

            transform: translateY(-25px);

            border-bottom: 1px solid $blue;

            &.active {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0px);
            }

            #{$s}__menu {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-top: 1px solid $blue;
                padding-top: 46px;

                &-item {
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: rgba($white, .08);
                        border: 1px solid rgba($white, .10);
                        color: $white;
                        text-decoration: none;
                        padding: 18px 24px;
                        border-radius: 80px;
                        width: 100%;

                        &::after {
                            content: '';
                            display: inline-block;
                            width: 5px;
                            height: 10px;

                            mask: $icon-arrow no-repeat 50% 50%;
                            -webkit-mask: $icon-arrow no-repeat 50% 50%;
                            background: $pool;

                            transform: rotate(180deg);
                            margin-left: 10px;
                            margin-right: 6px;
                        }
                    }
                }
            }

            #{$s}__menu {
                width: 100%;
            }

            #{$s}__menu-others {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 24px;

                &-item {
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid rgba($white, .10);
                        color: $white;
                        text-decoration: none;
                        padding: 8px;
                        border-radius: 80px;
                    }
                }
            }

            #{$s}__language {
                margin-top: 32px;
            }

            #{$s}__social {
                margin-top: 32px;
            }

            @media screen and (min-width: 1024px) {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @media screen and (min-width: 1024px) {
                display: flex;
                align-items: center;
                width: 100%;
            }

            #{$s}__menu {
                $menus: &;
                z-index: 50;
                transform: scaleY(0);
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: center top;
                padding-top: 100px;
                padding-left: 6%;
                padding-right: 5%;
                padding-bottom: 16px;
                border-radius: 0 0 32px 32px;
                width: 100%;

                @media screen and (min-width: 1024px) {
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(89.87deg, rgba(255, 255, 255, 0) -13.56%, rgba(255, 255, 255, 0.05) 99.88%);
                    border: 1px solid rgba($white, .10);
                    border-radius: 80px;
                    padding: 14px 16px;
                    position: relative;
                    transform: scaleY(1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: none;
                    width: auto;
                    justify-content: space-between; 
                    margin: 0 auto;

                    &-item {
                        display: flex !important;
                    }
                }

                &.active {
                    transform: scaleY(1);
                    transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
                    transition-delay: 0s;
                    background-color: $white;
                    z-index: 40;
                    margin-top: -16px;

                    #{$menus}-item {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
                        transition-delay: .4s;
                    }
                }

                &-item {
                    margin: 0;
                    transform: translateY(-2rem);
                    opacity: 0;
                    visibility: hidden;
                    display: block;
                    border-radius: 80px;
                    transition: .4s;
                    border: 1px solid rgba($white, 0);

                    @media(min-width: 1024px) {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                        padding: 8px;
                    }

                    &:hover {
                        @media(min-width: 1024px) {
                            background-color: rgba($white, .08);
                            border: 1px solid rgba($white, .10);
                        }
                    }

                }

                li {
                    margin-bottom: 34px;

                    @media(min-width: 1024px) {
                        margin: 0 8px 0
                    }
                }

                a {
                    color: $white;
                    font-size: 20px;
                    text-decoration: none;
                    transition: .4s;

                    #{$s}.active & {
                        color: $dark-blue;
                    }

                    @media(min-width: 1024px) {
                        font-size: 16px;
                    }

                    &.active {
                        color: $dark-blue;

                        @media(min-width: 1024px) {
                            color: $dark-blue;
                        }
                    }

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    &__menu-others {
        margin-top: 32px;
    }

    &__social {
        display: flex;
        align-items: center;

        &-item {
            margin-right: 8px;
            background-color: rgba($white, .08);
            border: 1px solid rgba($white, .10);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .4s;

            &:hover {
                background-color: rgba($white, .10);
            }

            img {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__language {
        display: flex;
        align-items: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(89.87deg, rgba(255, 255, 255, 0) -13.56%, rgba(255, 255, 255, 0.05) 99.88%);
        border: 1px solid rgba($white, .10);

        border-radius: 80px;
        padding: 12px 16px;

        #{$s}.active & {
            z-index: 50;
        }

        &-item {
            $item: &;
            margin-right: 8px;

            display: flex;
            align-items: center;
            justify-content: center;
            transition: .4s;
            padding: 10px 8px;
            border-radius: 80px;

            &:hover {
                background-color: rgba($white, .10);
            }

            &.active {
                background-color: rgba($white, .08);
                border: 1px solid rgba($white, .10);

                span {
                    font-weight: 600;

                }
            }

            img {
                display: block;
            }

            span {
                display: inline-block;
                color: $white;
                text-transform: uppercase;
                margin-left: 8px;

                #{$s}.active & {
                    color: $white;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;

        @media(min-width: 1024px) {}

        &-item {
            margin-right: 8px;
            background-color: rgba($white, .08);
            border: 1px solid rgba($white, .10);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .4s;

            &:hover {
                background-color: rgba($white, .10);
            }

            img {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__hamburguer {
        $s: &;
        position: relative;
        display: block;
        width: 48px;
        height: 48px;
        z-index: 100;
        border-radius: 50%;
        transition: .4s;
        background-color: rgba($white, .08);
        border: 1px solid rgba($white, .10);
        border-radius: 50%;

        @media(min-width: 1024px) {
            display: none;
        }

        &.active span {
            background-color: transparent;

            &::after,
            &::before {
                background-color: $pool;
                transition-delay: 0s, 0.2s;
            }

            &::before {
                top: 0;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }

        span {
            display: block;
            background-color: $pool;
            width: 22px;
            height: 2px;
            margin-top: -1px;
            font: 0 / 0 a;
            text-shadow: none;
            color: transparent;
            transition: background-color 0.2s ease-in-out;
            position: absolute;
            left: calc(50% - 11px);
            top: 50%;
            bottom: auto;

            &::after,
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-color: inherit;
                transition-duration: 0.2s, 0.2s;
                transition-delay: 0.2s, 0s;
                position: absolute;
                left: 0;
            }

            &::before {
                top: -8px;
                transition-property: top, transform;
            }

            &::after {
                bottom: -8px;
                transition-property: bottom, transform;
            }
        }
    }
}