.contact-form {
    margin-top: 120px;

    @media screen and (min-width: 1024px) {
        margin-top: 216px;
    }

    .wrap {
        position: relative;

        @media screen and (min-width: 1024px) {
            display: flex;
            gap: 90px;
        }
    }

    &__float-icon {
        position: absolute;
        left: -43px;
        bottom: 0;
        transform: rotate(26deg);
        z-index: -1;
    }

    &__column {
        &:first-child {
            @media screen and (min-width: 1024px) {
                min-width: 30%;
            }
        }
    }

    &__title {
        position: relative;
        width: 100%;
        color: $white;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;

        @media screen and (min-width: 1024px) {
            font-size: 48px;
            line-height: 0.7;
        }

        b {
            color: $orange;
        }
    }

    &__text {
        margin-top: 42px;
        color: $white;
        font-weight: 300;
        line-height: 1.4;

        @media screen and (min-width: 1024px) {
            margin-top: 36px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        margin-top: 42px;

        @media screen and (min-width: 1024px) {
            align-items: flex-start;
            margin-top: 48px;
        }

        &-item {
            margin-right: 8px;
            background-color: rgba($white, 0.08);
            border: 1px solid rgba($white, 0.1);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.4s;

            &:hover {
                background-color: rgba($white, 0.1);
            }

            img {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__form {
        margin-top: 40px;
        background-color: $blue;
        padding: 32px;
        border-radius: 16px;

        @media screen and (min-width: 1024px) {
            padding: 48px;
            margin-top: 0;
        }
    }

    &__fields {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__field {
        margin-bottom: 12px;
        width: 100%;

        @media screen and (min-width: 1024px) {
            margin-bottom: 0;
            width: calc(50% - 8px);
        }

        &--full {
            margin-bottom: 24px;
            padding-bottom: 24px;

            border-bottom: 1px solid rgba($marine, 0.3);

            @media screen and (min-width: 1024px) {
                width: 100%;
            }
        }

        input {
            font-family: "Inter", sans-serif;
            background-color: rgba($white, 0.08);
            border: 1px solid rgba($white, 0.1);
            padding: 21px 12px;
            width: 100%;
            border-radius: 6px;
            color: rgba(#fefdfd, 0.7);

            &::placeholder {
                color: rgba(#fefdfd, 0.7);
            }

            &:focus {
                outline: none;
                border-color: $pool;
            }
        }

        textarea {
            font-family: "Inter", sans-serif;
            background-color: rgba($white, 0.08);
            border: 1px solid rgba($white, 0.1);
            padding: 21px 12px;
            width: 100%;
            border-radius: 6px;
            color: rgba(#fefdfd, 0.7);
            min-height: 150px;

            &::placeholder {
                color: rgba(#fefdfd, 0.7);
            }

            &:focus {
                outline: none;
                border-color: $pool;
            }
        }
    }

    &__accept {
        display: flex;
        align-items: start;
        gap: 8px;

        color: $white;
        line-height: 1.3;
        font-size: 12px;

        input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            min-width: 16px;
            height: 16px;
            border: 2px solid #47bca1;
            border-radius: 2px;
            background-color: transparent;
            cursor: pointer;
            position: relative;

            &:checked {
                background-color: #47bca1;
                border-color: #47bca1;
            }

            &:checked::after {
                content: "";
                position: absolute;
                top: 0px;
                left: 3px;
                width: 4px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        .wpcf7-list-item {
            margin: 0;

            label {
                &:not([class]) {
                    display: flex;
                    gap: 8px;
                }
            }
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &__button {
        input {
            display: inline-block;
            background: linear-gradient(87.46deg, #47bca1 5.42%, #296caf 147.88%);
            border-radius: 80px;
            padding: 16px 40px;
            letter-spacing: 0.24em;
            text-transform: uppercase;
            color: $light-gray;
            font-weight: 300;
            margin-top: 32px;
            border: 0;
            width: 100%;
            cursor: pointer;

            @media screen and (min-width: 1024px) {
                padding: 18px 80px;
                margin-top: 48px;
            }
        }
    }

    &__success {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $white;
        border-radius: 8px;
        padding: 70px 40px;
        z-index: 999;
        min-width: 924px;

        &.active {
            display: block;
        }

        &-close {
            position: absolute;
            right: 40px;
            top: 40px;
            -webkit-mask: url(../images/icon-close.svg) no-repeat;
            mask: url(../images/icon-close.svg) no-repeat;
            background: $marine;
            width: 26px;
            height: 26px;
            background-size: cover;
            display: block;
            cursor: pointer;
        }

        &-title {
            text-align: center;
            width: 100%;
            font-size: 32px;
            line-height: 1.4;
            color: $marine;
            display: block;
        }

        &-text {
            text-align: center;
            width: 100%;
            margin-top: 24px;
            color: $marine;
            font-size: 18px;
        }

        iframe {
            max-height: 188px;
        }
    }

    .wpcf7-list-item {
        margin: 0;
    }

    .wpcf7-response-output {
        padding: 1rem !important;
        color: $white;
        line-height: 1.3;
        border-color: transparent !important;
        text-align: center;
    }

    form {
        &.sent .wpcf7-response-output {
            border-color: #2ecc71 !important;
            background: #2ecc71 !important;
        }

        &.failed .wpcf7-response-output,
        &.aborted .wpcf7-response-output {
            border-color: #e74c3c !important;
            background: #e74c3c !important;
        }

        &.invalid .wpcf7-response-output,
        &.unaccepted .wpcf7-response-output,
        &.payment-required .wpcf7-response-output {
            border-color: #f1c40f !important;
            background: #f1c40f !important;
        }
    }
}