.highlighted-categories {
    margin-top: 56px;

    @media (min-width: 1024px) {
        margin-top: 64px;
    }

    &__title {
        font-size: 32px;
        font-weight: 300;
        color: $white;
        line-height: 1.2;

        b {
            color: $coral;
        }
    }

    &__list {
        margin-top: 32px;
        overflow-x: auto;
        display: flex;
        gap: 8px;

        @media (min-width: 1024px) {
            margin-top: 40px;
            overflow-x: hidden;
            display: block;
            gap: 0px;
        }
    }

    &__item {
        display: inline-block;
        background-color: $pool;
        padding: 8px 16px;
        border-radius: 24px;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        margin-bottom: 8px;
        flex: 0 0 auto;

        @media (min-width: 1024px) {
            flex: unset;
        }
    }
}