.send-resume {
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;

    @media screen and (min-width: 1024px) {
        margin-top: 94px;
        margin-bottom: 0px;
        padding: 106px 0;
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__content {
        width: 100%;
        font-size: 20px;
        line-height: 1.4;
        color: $white;

        p {
            font-weight: 300;
        }
    }

    &__button {
        display: inline-block;
        background: linear-gradient(87.46deg, #47BCA1 5.42%, #296CAF 147.88%);
        border-radius: 80px;
        padding: 16px 32px;
        letter-spacing: 0.24em;
        text-transform: uppercase;
        color: $light-gray;
        font-weight: 300;
        margin-top: 32px;
        width: 100%;

        @media screen and (min-width: 1024px) {
            padding: 22px 80px;
            margin-top: 48px;
            width: auto;
        }
    }
}