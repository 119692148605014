.header-blog {
    margin-top: 140px;

    @media (min-width: 768px) {
        margin-top: 216px;
    }

    &__title {
        font-size: 40px;
        font-weight: 700;
        color: $white;
        line-height: 1.2;

        @media (min-width: 1024px) {
            font-size: 48px;
        }

        b {
            color: $coral;
        }
    }

    &__text {
        margin-top: 24px;
        color: $white;
        line-height: 1.4;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid $blue;

        @media (min-width: 1024px) {
            margin-top: 38px;
            padding-bottom: 64px;
            margin-bottom: 72px;
        }
    }
}