.privacy-policy {
  color: $white;
  padding-top: 140px;
  padding-bottom: 56px;

  @media (min-width: 768px) {
    padding-top: 206px;
    padding-bottom: 106px;
  }

  &__main-header {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid $blue;

    @media (min-width: 768px) {
      padding-bottom: 64px;
      margin-bottom: 72px;

    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 24px;

    @media (min-width: 768px) {
      margin-top: 48px;
    }
  }

  &__text {
    line-height: 1.4;

    p {
      line-height: 1.4;
      margin-bottom: 16px;
    }

    li {
      line-height: 1.4;
      margin-bottom: 16px;
    }

    h2 {
      font-weight: 700;
      color: $pool;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 700;
      color: $orange;
      margin-bottom: 16px;
    }

    a {
      color: $white;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    hr {
      margin: 32px 0;
      border-color: $blue;
    }

    ol {
      list-style: decimal;
      padding-left: 24px;
    }
  }
}