.portfolio-details {
    padding: 35px 0;
    position: relative;
    background-color: $light-gray;
    overflow: hidden;

    .container{
        z-index: 10;
    }

    &__title {
        color: $marine;
        font-size: 32px;
        line-height: 1.4;
        font-weight: 700;

        @media screen and (min-width: 1024px) {
            font-size: 42px;
        }
    }

    &__list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (min-width: 1024px) {
            margin-top: 40px;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__item {
        background-color: $white;
        border-radius: 8px;
        padding: 40px;

        @media screen and (min-width: 1024px) {
            &:first-child {
                width: 36.69%;
            }

            &:nth-child(2) {
                width: 28.22%;
            }

            &:nth-child(3) {
                width: 32.41%;
            }
        }

        &-title {
            color: $coral;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.4;
        }

        &-info {
            margin-top: 20px;

            @media screen and (min-width: 1024px) {
                margin-top: 28px;
            }
        }

        &-key {
            margin-right: 12px;
        }

        &-data {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:last-child {
                margin-bottom: 0;
            }

            @media screen and (min-width: 1024px) {
                margin-bottom: 28px;
            }

            p {
                color: $marine;
                line-height: 1.4;
            }
        }

        &-val {
            font-weight: 700;
        }
    }

    &__disclaimer {
        font-size: 14px;
        line-height: 1.4;
        color: $marine;
        font-style: italic;

        @media screen and (min-width: 1024px) {
            width: 100%;
            text-align: right;
        }
    }

    &__float-icon {
        &--left {
            display: none;

            @media screen and (min-width: 1024px) {
                display: block;
                position: absolute;
                bottom: 38px;
                left: 0;
                z-index: 5;
                width: 559px;
            }
        }

        &--right {
            position: absolute;
            right: -233px;
            top: -7px;
            z-index: 5;
        }
    }
}