.social-impact {
    margin-bottom: 60px;

    @media screen and (min-width: 1024px) {
        margin-bottom: 162px;
    }

    &__title {
        position: relative;
        width: 100%;
        color: $white;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 62px;

        @media screen and (min-width: 1024px) {
            margin-bottom: 116px;
            font-size: 72px;
            line-height: 0.7;
        }

        b {
            color: $orange;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: unset !important;

        @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1024px) {
            display: block;
        }

        .swiper-wrapper {

            @media screen and (min-width: 1024px) {
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    &__item {
        cursor: pointer;
        border-radius: 16px;
        overflow: hidden;
    }

    &__text {
        margin-top: 44px;
        color: $white;
        line-height: 1.3;

        a {
            color: $coral;
            font-weight: 500;
        }
    }
}