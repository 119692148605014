.text {
    font-size: 14px;
    color: $white;
    line-height: 1.4;
    text-align: justify;
    font-weight: 300;

    .wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__image {
        min-width: 120px;
    }

    a {
        color: $coral;
        font-weight: 500;
    }
}