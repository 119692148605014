.pillars {
    $s: &;
    background-color: $marine;
    padding-bottom: 60px;
    position: relative;

    &--about {
        @media screen and (min-width: 1024px) {
            margin-top: 164px;
        }
    }

    &--export {
        background-color: $white;
        padding-top: 50px;
        padding-bottom: 40px;

        @media screen and (min-width: 1024px) {
            padding-top: 80px;
            padding-bottom: 0;
        }

        .wrap {
            top: 20px;
            margin-bottom: 20px;

            @media screen and (min-width: 1024px) {
                top: 40px;
                margin-bottom: 40px;

            }
        }
    }

    &__title {
        position: relative;
        width: 100%;
        color: $coral;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;

        &--coral {
            color: $coral;
        }

        @media screen and (min-width: 1024px) {
            font-size: 104px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &::after {
            @media screen and (min-width: 1024px) {
                content: url(../images/icon-pilares.svg);
                display: inline-block;
                font-size: 1px;
            }
        }


        #{$s}--export & {
            @media screen and (min-width: 1024px) {
                font-size: 70px;
            }

            &::after {
                @media screen and (min-width: 1024px) {
                    display: none;
                }
            }
        }
    }

    &__list {
        margin-top: 24px;
        row-gap: 16px;
        padding-bottom: 32px;

        @media screen and (min-width: 1024px) {
            margin-top: 0px;
            position: relative;
            top: 40px;
        }

        .swiper-wrapper {
            @media screen and (min-width: 1024px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 16px;
            }
        }
    }

    &__item {
        width: 100%;
        max-width: 298px;
        border-radius: 8px;
        height: auto;
        padding: 40px;
        border: 1px solid $blue;
        background-color: $marine;

        &--title {
            color: $coral;
            margin-top: 48px;
            font-size: 24px;
            line-height: 1.4;
            font-weight: 700;
        }

        &--desc {
            margin-top: 28px;

            p {
                color: $white;
                line-height: 1.4;
            }
        }
    }

    .swiper-wrapper {
        &::after {
            content: "";
            height: 100%;
            display: block;
            width: 32px;
        }
    }

    .swiper-pagination {

        // bottom: -32px;
        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background-color: $blue;
            opacity: 1;

            &-active {
                background-color: $pool;
            }
        }

        @media screen and (min-width: 1024px) {
            display: none;
        }
    }

    .swiper-wrapper {}

    &__float-icon {
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
            position: absolute;
            right: 0;
            top: 40px;
        }
    }
}