.search-data {
    &__name {
        font-style: italic;
        color: $white;

        strong {
            font-style: normal;
            font-weight: 700;
            color: $coral;
        }
    }

    &__total {
        margin-top: 12px;
        font-style: italic;
        color: $white;
        font-weight: 300;
    }
}