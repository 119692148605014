.video-banner {
    position: relative;
    background-color: $marine;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__video {
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .2;
        }
    }

    .wrap {
        height: 100%;
    }

    &__title {
        font-size: 40px;
        color: $white;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        line-height: 1.4;

        @media screen and (min-width: 1024px) {
            font-size: 80px;
        }

        b {
            color: $coral;
        }
    }
}