.articles-list {
    margin-top: 32px;
    display: grid;
    gap: 16px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        margin-top: 40px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
    }

    &__image {
        height: 192px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        padding: 24px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        @media (min-width: 1024px) {
            padding: 32px;
        }
    }

    &__item-category {
        display: inline-block;
        background-color: $pool;
        padding: 8px 16px;
        border-radius: 24px;
        font-size: 14px;
        text-transform: uppercase;
        color: $white;
        text-align: center;

        @media (min-width: 1024px) {
            margin-top: 0;
        }
    }

    &__item-title {
        color: $marine;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 16px;
    }
}