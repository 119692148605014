.addresses {
    background-color: $dark-blue;
    padding-bottom: 32px;

    @media screen and (min-width: 768px) {
        padding: 48px 0;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        background-color: rgba($white, 0.08);
        border-radius: 8px;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        border: 1px solid rgba($white, 0.1);

        @media screen and (min-width: 768px) {
            justify-content: center;
        }
        &--flag {
            margin-right: 8px;
        }

        &--country {
            color: $white;
            text-transform: uppercase;
            font-weight: 700;

            &::after {
                @media screen and (min-width: 768px) {
                    content: "|";
                    font-weight: 400;
                    margin: 0 8px;
                }
            }
        }

        &--address {
            color: $white;
            line-height: 1.4;
            margin-top: 8px;
            
            @media screen and (min-width: 768px) {
                text-align: center;
            }

            @media screen and (min-width: 1024px) {
                text-align: left;
                margin-top: 0px;
            }
        }
    }
}
