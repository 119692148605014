.faq {
    $s: &;
    padding: 46px 0;

    &--top-bottom-line {
        position: relative;
        margin-top: 80px;
        margin-bottom: 46px;
        padding: 80px 0;

        &::before {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%);
            content: '';
            width: 100%;
            height: 1px;
            max-width: 196px;
            background-color: $blue;
            display: inline-block;

            @media screen and (min-width: 1024px) {
                max-width: 578px;
            }
        }

        &::after {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
            content: '';
            width: 100%;
            height: 1px;
            max-width: 196px;
            background-color: $blue;
            display: inline-block;

            @media screen and (min-width: 1024px) {
                max-width: 578px;
            }
        }

        @media screen and (min-width: 1024px) {
            margin-top: 140px;
            margin-bottom: 140px;
            padding: 140px 0 140px 0 !important;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 140px 0 70px 0;
    }

    .wrap {
        @media screen and (min-width: 1024px) {
            padding: 0;
        }
    }

    &__pre-title {
        display: block;
        font-weight: 300;
        color: $marine;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        text-align: center;

        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }
    }

    &__title {
        color: $blue;
        font-size: 32px;
        margin-top: 8px;
        font-weight: 300;
        line-height: 1.1;
        text-align: center;

        &--white {
            color: $white;

            strong {
                color: $white !important;
            }
        }

        @media screen and (min-width: 1024px) {
            font-size: 40px;
            margin-top: 14px;
        }

        strong {
            color: $blue;
            font-weight: 700;
        }
    }

    &__list {
        #{$s}--publications & {
            margin-top: 40px;

            @media screen and (min-width: 1024px) {
                margin-top: 80px;
            }
        }
    }

    &__item {
        $item: &;
        border: 1px solid $blue;
        margin-bottom: 16px;
        border-radius: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &--question {
            position: relative;
            font-weight: 700;
            color: $white;
            display: flex;
            align-items: center;
            padding: 32px;
            transition: .4s;
            line-height: 1.2;

            #{$item}.active & {
                padding-bottom: 0px;
            }

            @media screen and (min-width: 1024px) {
                font-size: 18px;
                cursor: pointer;
            }
        }

        &--icon {
            width: 19px;
            height: 10px;
            background-color: $pool;
            -webkit-mask-image: url(../images/icon-down.svg);
            mask-image: url(../images/icon-down.svg);
            transition: .4s;

            position: absolute;
            right: 32px;

            #{$item}.active & {
                transform: rotate(180deg);
            }
        }

        &--answer {
            color: $white;
            font-size: 16px;
            line-height: 1.4;
            padding: 0;
            max-height: 0;
            transition: .4s;
            overflow: hidden;
            padding: 0 32px;
            opacity: 0;

            #{$item}.active & {
                max-height: 1000px;
                padding: 32px;
                opacity: 1;
            }

            p {
                margin-bottom: 10px;

                &:first-child {
                    border-top: 1px solid $blue;
                    padding-top: 32px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}