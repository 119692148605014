.fund-data {
  padding: 32px 0;
  @media screen and (min-width: 1024px) {
    padding: 64px 0 88px 0;
  }
  &__title {
    color: $white;
    font-size: 24px;
  }

  &__list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    @media screen and (min-width: 1024px) {
      margin-top: 40px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    border-radius: 8px;
    border: 1px solid $blue;
    padding: 40px;
    @media screen and (min-width: 1024px) {
      padding: 40px;
    }
    &-title {
      color: $coral;
      font-weight: 700;
      font-size: 24px;
    }
    &-desc {
      margin-top: 14px;
      font-weight: 700;
      color: $white;
      line-height: 1.4;
    }
  }
}
