.simple-disclaimer {
    padding: 34px 0;

    @media screen and (min-width: 1024px) {
        padding: 72px 0;
    }

    &__title {
        position: relative;
        color: $white;
        font-size: 40px;
        font-weight: 700;

        &::after {
            @media screen and (min-width: 1024px) {
                content: url(../images/icon-pilares.svg);
                display: inline-block;
                font-size: 1px;
            }
        }

        @media screen and (min-width: 1024px) {
            font-size: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__desc {
        color: $white;
        margin-top: 24px;
        line-height: 1.4;

        a {
            color: $coral;
            text-decoration: underline;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 32px;
        }
    }
}