.select-language {
    position: relative;
    background-color: $marine;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__video {
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .2;
        }
    }

    .wrap {
        height: 100%;
    }

    &__title {
        font-size: 40px;
        color: $white;
        font-weight: 700;
        text-align: center;

        b {
            color: $coral;
        }
    }

    &__subtitle {
        margin-top: 24px;
        font-size: 24px;
        color: $white;
        font-style: italic;
        font-weight: 400;
        text-align: center;
    }

    &__list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            margin-top: 72px;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-item {
            $s: &;
            position: relative;
            display: flex;
            padding: 16px 48px 16px 16px;
            background: rgba($white, 0.08);
            border-radius: 80px;
            color: $white;
            text-align: left;
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            width: 100%;

            &:hover {
                &::after {
                    opacity: 1;
                }

                #{$s}--icon {
                    opacity: 0;

                    &-hover {
                        opacity: 1;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 50px;
                padding: 2px;
                /* control the border thickness */
                background: linear-gradient(to right, $white, transparent);
                -webkit-mask:
                    linear-gradient($white 0 0) content-box,
                    linear-gradient($white 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
                transition: .4s;
            }

            &::after {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 50px;
                padding: 2px;
                /* control the border thickness */
                background: linear-gradient(to right, $pool, transparent);
                -webkit-mask:
                    linear-gradient($pool 0 0) content-box,
                    linear-gradient($pool 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
                opacity: 0;
                transition: .4s;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media screen and (min-width: 1024px) {
                margin-bottom: 0;
                margin-right: 12px;
                width: auto;
                min-width: 376px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &--title {
                font-weight: 600;
                line-height: 1.2;
            }

            &--subtitle {
                font-weight: 300;
                font-style: italic;
                line-height: 1.2;
            }

            &--icon {
                margin-right: 16px;
                transition: .4s;

                &-hover {
                    position: absolute;
                    opacity: 0;
                }
            }

            &--arrow {
                position: absolute;
                right: 32px;
            }
        }
    }
}