$marine: #1A3153;
$coral: #F26651;
$light-gray: #EDEDEE;
$dark-gray: #47494B;
$yellow: #E7E767;
$orange: #F78722;
$bordo: #DB1C4A;
$medium-gray: #878B8E;
$blue: #296CAF;
$pool: #47BCA1;
$white: #FFFFFF;
$dark-blue: #0A192E;
$dark: #313131;