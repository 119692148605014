.footer-disclaimer {
    background-color: #0a192e;

    @media screen and (min-width: 1024px) {
        padding-bottom: 56px;
    }

    .wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid rgba($light-gray, .3);
        padding: 32px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: flex-start;
            padding-top: 48px;
        }
    }

    &__image {
        @media screen and (min-width: 1024px) {
            margin-right: 36px;
        }
    }

    &__content {
        margin-top: 24px;

        @media screen and (min-width: 1024px) {
            margin-top: 0px;
        }

        p {
            color: $white;
            font-size: 10px;
            line-height: 1.2;
        }

        a {
            color: $white;
            font-weight: 600;
        }
    }
}