.header-page {
  margin-top: 96px;
  padding-top: 32px;
  background-color: $white;

  @media (min-width: 768px) {
    margin-top: 168px;
    padding-top: 64px;
  }

  .wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__back {
    display: flex;
    align-items: center;
    color: $dark-blue;
    text-transform: uppercase;
    letter-spacing: 0.24rem;
    border: 2px solid $marine;
    border-radius: 80px;
    padding: 14px 32px 14px 30px;
    line-height: 16px;
    transition: 0.4s;

    @media (min-width: 768px) {
      position: absolute;
      left: 8px;
      top: 0;
    }

    &:hover {
      background-color: $marine;
      color: $white;

      &::before {
        background: $white;
      }
    }

    &::before {
      content: "";
      margin-right: 16px;
      transition: 0.4s;
      width: 5px;
      height: 14px;

      mask: $icon-arrow no-repeat 50% 50%;
      -webkit-mask: $icon-arrow no-repeat 50% 50%;
      background: $marine;
    }
  }

  &__category {
    background-color: $pool;
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    margin-top: 32px;

    @media (min-width: 1024px) {
      margin-top: 0;
    }
  }

  &__title {
    font-size: 24px;
    color: $dark;
    margin-top: 24px;
    max-width: 614px;
    text-align: center;
    line-height: 1.2;
    font-weight: 700;

    @media (min-width: 1024px) {
      margin-top: 32px;
      font-size: 32px;
    }
  }

  &__infos {
    margin-top: 32px;
    font-size: 16px;
    font-style: italic;
    line-height: 1.2;
    font-weight: 300;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  &__thumbnail {
    width: 100%;
    border-radius: 16px;
    margin-top: 32px;

    @media (min-width: 1024px) {
      margin-top: 40px;
    }
  }
}