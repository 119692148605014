.compliance-policy {
  color: $white;
  padding-top: 140px;

  @media (min-width: 768px) {
    padding-top: 206px;
    padding-bottom: 106px;
  }

  &__main-header {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid $blue;

    @media (min-width: 768px) {
      padding-bottom: 64px;
      margin-bottom: 72px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 24px;

    @media (min-width: 768px) {
      margin-top: 48px;
    }
  }

  &__list {
    max-width: 926px;
    margin: 0 auto;
  }

  &__text {
    line-height: 1.4;

    p {
      line-height: 1.4;
      margin-bottom: 16px;
    }

    li {
      line-height: 1.4;
      margin-bottom: 16px;
    }

    h2 {
      font-weight: 700;
      color: $pool;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 700;
      color: $orange;
      margin-bottom: 16px;
    }

    a {
      color: $white;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    hr {
      margin: 32px 0;
      border-color: $blue;
    }

    ol {
      list-style: decimal;
      padding-left: 24px;
    }
  }

  &__item {
    padding: 12px 24px;
    padding-right: 36px;
    border-radius: 8px;
    line-height: 1.4;
    color: $white;
    border: 1px solid $blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s;
    background: $blue;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      padding: 24px 22px;
    }

    &:hover {
      background: $blue;
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='10' height='19' viewBox='0 0 10 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 17.5L9 9.5L0.999999 1.5' stroke='%2347BCA1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      height: 18px;
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2Z' fill='%2347BCA1'/%3E%3C/svg%3E%0A");
      height: 16px;
    }
  }
}