.featured-articles {

    &__title {
        width: 100%;
        font-size: 32px;
        color: $white;
        line-height: 1.2;
        font-weight: 300;

        strong {
            font-weight: 600;
        }

        b {
            color: $coral;
        }
    }

    &__list {
        cursor: url(../images/custom-cursor.svg), pointer;
        margin-top: 24px;
        overflow: unset !important;

        * {
            cursor: url(../images/custom-cursor.svg), pointer;
        }

        @media (min-width: 1024px) {
            margin-top: 48px;
        }
    }

    &__item {
        display: flex;
        flex: 0 0 auto;
        align-items: flex-end;
        border-radius: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 360px;
        height: 360px;

        &-content {
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-category {
            background-color: $pool;
            padding: 8px 16px;
            border-radius: 24px;
            font-size: 14px;
            text-transform: uppercase;
            color: $white;
            text-align: center;
        }

        &-title {
            font-weight: 700;
            color: $white;
            line-height: 1.3;
            font-size: 20px;
            margin-top: 24px;
        }

        &-date {
            margin-top: 24px;
            color: $white;
            font-weight: 300;
            font-style: italic;
        }
    }

    &__see-all-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__see-all {
        min-width: 297px;
        margin-top: 48px;
        display: inline-block;
        padding: 12px;
        border-radius: 80px;

        line-height: 1.6;
        letter-spacing: 0.24em;
        text-transform: uppercase;
        color: $light-gray;
        text-align: center;
        border: 2px solid $light-gray;

        @media (min-width: 1024px) {
            margin-top: 56px;
        }
    }
}