.graphics {
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 90px;
        background-color: $light-gray;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }

    .container {
        z-index: 10;
    }

    &__list {
        display: grid;
        gap: 18px;

        @media screen and (min-width: 1024px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__item {
        background-color: $marine;
        border-radius: 8px;
        border: 1px solid $blue;
        padding: 24px;

        @media screen and (min-width: 1024px) {
            padding: 40px;
        }
        
        &-title {
            color: $white;
            font-style: italic;
            font-size: 20px;
            line-height: 1.4;
            width: 100%;
            padding-bottom: 20px;
            margin-bottom: 40px;
            border-bottom: 1px solid $coral;

            @media screen and (min-width: 1024px) {
                font-size: 24px;
            }

            strong {
                font-style: normal;
            }
        }
    }

    svg[id^="raphael-paper"] {
        background-color: transparent !important;
    }
}
