.copyright {
    background-color: $marine;
    padding: 32px 0;
    color: $white;

    @media screen and (min-width: 1024px) {
        text-align: center;
    }

    .wrap {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__somo {
        margin-top: 8px;

        @media screen and (min-width: 1024px) {
            margin-top: 0px;
        }
    }

    p {
        line-height: 1.4;
        font-size: 14px;

        @media screen and (min-width: 1024px) {
            font-size: 12px;
        }
    }

    strong {
        font-weight: 600;
    }

    a {
        color: $white;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;
    }
}
