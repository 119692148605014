.our-strategies {
    $s: &;
    overflow: hidden;
    padding: 48px 0;
    position: relative;
    background-color: $white;

    @media screen and (min-width: 1024px) {
        padding: 146px 0 100px 0;
    }

    .wrap {
        z-index: 15;

        @media screen and (min-width: 1024px) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__title {
        color: $blue;
        font-weight: 700;
        font-size: 40px;
        text-transform: uppercase;

        br {
            display: none;
        }

        @media screen and (min-width: 1024px) {
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            text-align: right;
            transform: rotate(-90deg) translateY(-80%) translateX(-30%);
            text-transform: uppercase;

            br {
                display: block;
            }
        }

        span {
            @media screen and (min-width: 1024px) {
                display: block;
                transform: rotate(270deg);
            }
        }
    }

    &__cards {
        margin-top: 32px;

        @media screen and (min-width: 1024px) {
            width: 85%;
            display: grid;
            column-gap: 22px;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            margin-top: 0px;
        }
    }

    &__item {
        border: 1px solid $blue;
        border-radius: 8px;
        background-color: $marine;
        padding: 72px 32px 32px 32px;
        color: $white;
        margin-bottom: 32px;
        z-index: 30;

        #{$s}--big & {
            padding: 32px;
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: 0;
            padding: 250px 48px 48px 48px;

            #{$s}--big & {
                padding: 48px;
            }
        }

        &--unique {
            @media screen and (min-width: 1024px) {
                margin-bottom: 0;
                padding: 48px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--title {
            font-size: 32px;
            color: $coral;
            font-weight: 700;
            line-height: 1.2;
        }

        &--desc {
            margin-top: 24px;

            &--max-width {
                max-width: 436px;
            }

            @media screen and (min-width: 1024px) {
                margin-top: 32px;
            }
        }

        &--button {
            display: inline-block;
            background: linear-gradient(87.46deg, #1757b6 5.42%, #296caf 147.88%);
            border-radius: 80px;
            padding: 16px 40px;
            letter-spacing: 0.24em;
            text-transform: uppercase;
            color: $light-gray;
            font-weight: 300;
            margin-top: 32px;

            @media screen and (min-width: 1024px) {
                padding: 22px 40px;
                margin-top: 40px;
            }
        }

        p {
            line-height: 1.4;
            font-weight: 300;
        }
    }

    &__float-icon {
        &--left {
            display: none;

            @media screen and (min-width: 1024px) {
                display: block;
                position: absolute;
                bottom: 38px;
                left: 0;
                z-index: 11;
                width: 559px;
            }
        }

        &--right {
            display: none;
            position: absolute;
            right: 0;
            top: -100px;
            z-index: 11;

            @media screen and (min-width: 1024px) {
                display: block;
            }
        }
    }

    &--clear {
        &::after {
            display: none;
        }
    }

    &--all-white {
        &::after{
            display: none;
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;

        border-left: 100vw solid transparent;
        border-right: 47px solid transparent;
        border-bottom: 160px solid $marine;

        @media screen and (min-width: 1024px) {
            border-bottom: 284px solid $marine;
        }
    }
}