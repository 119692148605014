.d-values {
    padding-bottom: 34px;

    @media screen and (min-width: 1024px) {
        background-image: url("../images/background-d-values.svg");
        background-size: cover;
        background-attachment: fixed;
        padding-bottom: 48px;
    }

    .wrap {
        @media screen and (min-width: 1024px) {
            min-height: 820px;
        }
    }

    &__title {
        position: relative;
        width: 100%;
        color: $pool;
        font-weight: 700;
        font-size: 48px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;

        @media screen and (min-width: 1024px) {
            font-size: 80px;
            line-height: 0.7;
            z-index: 15;
            padding-top: 25%;
        }

        @media screen and (min-width: 1240px) {
            padding-top: 15%;
        }

        b {
            color: $orange;
        }
    }

    &__cards {
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;

        @media screen and (min-width: 1024px) {
            position: absolute;
            display: block;
            height: 100%;
            margin-bottom: 0;
            margin-top: 0;
            min-height: 788px;
            width: 100%;
            top: 0;
        }
    }

    &__item {
        width: 100%;
        border-radius: 8px;
        padding: 32px;
        border: 1px solid $blue;
        background-color: $marine;

        @media screen and (min-width: 1024px) {
            max-width: 298px;
            padding: 40px;
            min-height: 328px;
            position: absolute;
        }

        &:nth-child(1) {
            @media screen and (min-width: 1024px) {
                left: 0%;
                top: 62px;
            }

            @media screen and (min-width: 1280px) {
                left: 4%;
                top: 62px;
            }
        }

        &:nth-child(2) {
            @media screen and (min-width: 1024px) {
                left: 12%;
                bottom: 0;
            }

            @media screen and (min-width: 1280px) {
                left: 22%;
                bottom: 0;
            }
        }

        &:nth-child(3) {
            @media screen and (min-width: 1024px) {
                right: 22%;
                bottom: 62px;
            }

            @media screen and (min-width: 1280px) {
                right: 22%;
                bottom: 62px;
            }
        }

        &:nth-child(4) {
            @media screen and (min-width: 1024px) {
                right: 0%;
                top: 0;
            }

            @media screen and (min-width: 1280px) {
                right: 4%;
                top: 0;
            }
        }

        &--title {
            color: $coral;
            margin-top: 24px;
            font-size: 24px;
            line-height: 1.4;
            font-weight: 700;

            @media screen and (min-width: 1024px) {
                margin-top: 48px;

            }
        }

        &--desc {
            margin-top: 28px;

            p {
                color: $white;
                line-height: 1.4;
            }
        }
    }
}