.what-we-do {
    padding: 42px 0;

    &__title {
        position: relative;
        width: 100%;
        color: $white;
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;

        @media screen and (min-width: 1024px) {
            font-size: 104px;
            line-height: 0.7;
            z-index: 15;
            top: 8px;
        }

        b {
            color: $orange;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 42px;
        padding-bottom: 42px;
        border-bottom: 1px solid transparent;
        border-image-source: linear-gradient(90deg,
                rgba(41, 108, 175, 0) 0%,
                rgba(41, 108, 175, 1) 50%,
                rgba(41, 108, 175, 0) 100%);
        border-image-slice: 1;

        @media screen and (min-width: 1024px) {
            margin-top: 64px;
            padding-bottom: 112px;
        }
    }

    &__item {
        padding: 32px;
        border: 1px solid $blue;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            gap: 32px;
        }

        &-icon {
            min-width: 60px;
            width: 60px;
        }

        &-title {
            font-size: 24px;
            color: $coral;
            font-weight: 700;
            text-align: center;

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }

        &-desc {
            margin-top: 14px;
            color: $white;
            font-size: 18px;
            line-height: 1.4;
            text-align: center;

            @media screen and (min-width: 768px) {
                text-align: left;
            }

            a {
                color: $coral;
                font-weight: 500;
            }
        }
    }
}