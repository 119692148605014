.main-footer {
    position: relative;
    background-color: #0a192e;
    padding: 64px 0;


    &::after {
        display: none;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            90deg,
            rgba(41, 108, 175, 0) 0%,
            rgba(41, 108, 175, 1) 50%,
            rgba(41, 108, 175, 0) 100%
        );
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }

    .wrap {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            align-items: flex-start;
        }
    }

    &__menu-wrap {
        margin-top: 24px;
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
            margin-top: 0px;
        }
    }

    &__menu {
        &--title {
            color: $white;
            font-weight: 700;
            text-align: center;
            margin-bottom: 24px;

            @media screen and (min-width: 1024px) {
                text-align: left;
            }
        }

        &--item {
            color: $white;
            margin-bottom: 24px;
            text-align: center;

            @media screen and (min-width: 1024px) {
                text-align: left;
                max-width: 200px;
            }

            a {
                color: $white;
                line-height: 1.2;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 64px;

        @media screen and (min-width: 1024px) {
            align-items: flex-start;
            margin-top: 0px;
        }

        &-item {
            margin-right: 8px;
            background-color: rgba($white, 0.08);
            border: 1px solid rgba($white, 0.1);
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.4s;

            &:hover {
                background-color: rgba($white, 0.1);
            }

            img {
                display: block;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
