.articles {
    .wrap {
        margin-top: 48px;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            margin-top: 56px;
            border-top: 1px solid $blue;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__left {
        padding-bottom: 80px;

        @media (min-width: 1024px) {
            padding-top: 48px;
            width: 75%;
            padding-right: 42px;
        }
    }

    &__sidebar {
        padding-top: 0px;

        .post-tags {
            display: none;
        }

        @media (min-width: 1024px) {
            padding-top: 48px;
            width: 25%;
            padding-left: 42px;
            border-left: 1px solid $blue;

            .post-tags {
                display: block;
            }
        }
    }

    &__see-more {
        text-align: center;
        width: 100%;
        a {
            display: inline-block;
            background: transparent;
            border: 2px solid $light-gray;
            border-radius: 80px;
            padding: 16px 40px;
            letter-spacing: 0.24em;
            text-transform: uppercase;
            color: $light-gray;
            font-weight: 300;
            margin-top: 32px;
            cursor: pointer;
            transition: .4s;

            &:hover{
                color: $marine;
                background: $white;
            }

            @media screen and (min-width: 1024px) {
                padding: 18px 80px;
                margin-top: 48px;
            }
        }
    }
}