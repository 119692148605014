.about {
    $s: &;
    position: relative;
    background-color: $marine;
    padding: 32px 0;
    overflow: hidden;

    display: flex;
    flex-direction: column-reverse;
    gap: 40px;

    @media screen and (min-width: 768px) {
        gap: 0;
        display: block;
    }

    &--big-numbers {
        svg {
            @media screen and (min-width: 1024px) {
                right: -540px !important
            }
        }
    }

    svg {
        height: auto;

        @media screen and (min-width: 1024px) {
            display: inline-block;
            position: absolute;
            top: -28px;
            right: -150px;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 110px 0;
    }

    .wrap {
        @media screen and (min-width: 1024px) {
            display: flex;
            gap: 120px;
        }
    }

    &__content {
        @media screen and (min-width: 1024px) {
            max-width: 556px;
        }
    }

    &__title {
        color: $blue;
        font-weight: 700;
        font-size: 40px;
    }

    &__desc {
        margin-top: 32px;

        @media screen and (min-width: 1024px) {
            margin-top: 42px;
        }

        p {
            color: $white;
            line-height: 1.4;

            @media screen and (min-width: 1024px) {
                font-size: 18px;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    &__button {
        display: inline-block;
        background: linear-gradient(87.46deg, #1757b6 5.42%, #296caf 147.88%);
        border-radius: 80px;
        padding: 16px 40px;
        letter-spacing: 0.24em;
        text-transform: uppercase;
        color: $light-gray;
        font-weight: 300;
        margin-top: 32px;

        @media screen and (min-width: 1024px) {
            padding: 22px 80px;
            margin-top: 48px;
        }
    }

    &__big-numbers {
        display: flex;
        flex-direction: column;
        margin-top: 36px;
        gap: 50px;

        @media screen and (min-width: 1024px) {
            gap: 90px;
        }

        &-item {
            position: relative;
            padding-left: 26px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 10px;
                height: 100%;
                -webkit-mask-image: url(../images/icon-big-number.svg);
                mask-image: url(../images/icon-big-number.svg);
                background-color: $pool;
                mask-size: cover;
            }
        }

        &-number {
            font-size: 36px;
            color: $coral;
            line-height: 1.4;
            font-weight: 700;

            @media screen and (min-width: 1024px) {
                font-size: 48px;
            }
        }

        &-desc {
            margin-top: 12px;
            line-height: 1.4;
            color: $white;
        }
    }

    svg {
        g {
            fill: #15426e;
            transition: 0.4s;
            background-color: red;

            &.south-america {
                &:hover {
                    fill: $blue !important;
                }
            }

            &.north-america {
                &:hover {
                    fill: $blue !important;
                }
            }
        }
    }
}